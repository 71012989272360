var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _vm.status == "complete"
        ? [
            _c("font-awesome-icon", {
              style: { color: "var(--status-green)" },
              attrs: { icon: ["far", "circle-check"] },
            }),
            _vm._v(" " + _vm._s(_vm.$t("milestone.status.complete")) + " "),
          ]
        : _vm._e(),
      _vm.status == "late"
        ? [
            _c("font-awesome-icon", {
              style: { color: "var(--status-red)" },
              attrs: { icon: ["far", "alarm-exclamation"] },
            }),
            _vm._v(" " + _vm._s(_vm.$t("milestone.status.late")) + " "),
          ]
        : _vm._e(),
      _vm.status == "soon"
        ? [
            _c("font-awesome-icon", {
              style: { color: "var(--status-orange)" },
              attrs: { icon: ["far", "clock"] },
            }),
            _vm._v(" " + _vm._s(_vm.$t("milestone.status.soon")) + " "),
          ]
        : _vm._e(),
      _vm.status == "pending"
        ? [
            _c("font-awesome-icon", {
              style: { color: "var(--status-blue)" },
              attrs: { icon: ["far", "circle-minus"] },
            }),
            _vm._v(" " + _vm._s(_vm.$t("milestone.status.pending")) + " "),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }